import icons from "url:../../img/icons.svg";

class LoginView {
  _parentElement = document.querySelector(".login");
  _loginMessage = document.querySelector(".login__message");
  _loginInput = document.querySelectorAll(".login__input");
  _loginPassword = document.querySelector(".login__password");
  _togglePassword = document.querySelector(".login__togglepassword");
  _errorMessage = "You have entered a wrong ID or password!";

  _clearMessage() {
    this._loginMessage.innerHTML = "";
  }

  //To insert and clear the waiting spinner icon while fetching from database
  renderSpinner() {
    const markup = `
	  <div class="login__spinner">
		<svg>
		  <use href="${icons}#icon-loader"></use>
		</svg>
	  </div>
	  `;
    this._clearMessage();
    this._loginMessage.insertAdjacentHTML("afterbegin", markup);
  }

  //To render error message for authentication error
  renderError(message = this._errorMessage) {
    const markup = `			  
    <p class="login__error-messge">${message}</p>`;
    this._clearMessage();
    this._loginMessage.insertAdjacentHTML("afterbegin", markup);
  }

  //To remove the login error message once user inputs either ID or password fields
  addHandlerRemoveErrorMessage() {
    this._loginInput.forEach((input) =>
      input.addEventListener("input", () => {
        this._clearMessage();
      })
    );
  }
  //To toggle the visibility of the password when click on the eye icon
  addHandlerTogglePassword() {
    this._togglePassword.addEventListener("click", () => {
      const type =
        this._loginPassword.getAttribute("type") === "password"
          ? "text"
          : "password";
      this._loginPassword.setAttribute("type", type);
      // toggle the icon
      this._togglePassword.classList.toggle("fa-eye-slash");
      this._togglePassword.classList.toggle("fa-eye");
    });
  }

  //To handle login
  addHandlerLogin(handler) {
    this._parentElement.addEventListener("submit", (e) => {
      e.preventDefault();
      const dataArr = [...new FormData(this._parentElement)]; //get all form data and spread operator into an array. Note that <input> > name is required for this to work.
      const data = Object.fromEntries(dataArr); //convert the array to Object
      handler(data); //pass the data to controller as new login
    });
  }

  //To handle SSO by receiving the token from the clicked source url and enable access without login
  addHandlerReceiveToken() {
    const allowDomain = ["https://tscms.trueshape.sg"]; //limit the source url that can poss the token
    // event listener to postMessage from source url. This has to be loaded first before the postMessage is executed.
    window.addEventListener("message", (e) => {
      if (allowDomain.includes(e.origin)) {
        // check if message is access token then set it to local storage, else remove access token and user name from local storage as it will be "logout" message from logoutView
        if (e.data.accesstoken) {
          localStorage.setItem(
            "accesstoken",
            JSON.stringify(e.data.accesstoken)
          );
          localStorage.setItem("username", JSON.stringify(e.data.username));
        } else {
          localStorage.removeItem("accesstoken");
          localStorage.removeItem("username");
        }
      }
    });
  }
}
export default new LoginView();
